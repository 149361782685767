import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { getFilterData } from '~/service/OfferService';
import { CidadeInterface, FranquiaInterface, ModalFilterOffers, OptionProps, SegmentoInterface } from '~/types';
import { AuthContext } from '~/context/AuthContext';
import { useErrorHandler } from 'react-error-boundary';
import { EmpType } from '~/enum';

const ViewModalFilterAvailableOffers: React.FC<ModalFilterOffers> = ({ filtersState, fetchOffers }) => {
  const handleError = useErrorHandler();
  const { company: empresa } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [segmentos, setSegmentos] = useState([] as Array<OptionProps>);
  const [cidades, setCidades] = useState([] as Array<OptionProps>);
  const [franquias, setFranquias] = useState<OptionProps[]>(
    empresa.EMP_TIPO === EmpType.FRANQUEADORA
      ? [
          {
            value: 0,
            label: 'De todas as franquias',
          },
        ]
      : [
          {
            value: empresa.EMP_TIPO === EmpType.FRANQUIA ? empresa.EMP_CODIGO : empresa.EMP_TIPO === EmpType.ASSOCIADO ? empresa.EMP_CODMATRIZ : '',
            label: 'Da minha Franquia',
          },
          {
            value: 0,
            label: 'De todas as franquias',
          },
        ]
  );

  useEffect(() => {
    getFilterData()
      .then(({ payload }) => {
        setSegmentos(payload?.segmentos.map((segmento: SegmentoInterface) => ({ value: segmento.SEG_CODIGO, label: segmento.SEG_DESCRICAO })) || []);
        setCidades(payload?.cidades.map((cidade: CidadeInterface) => ({ value: cidade.LEN_CIDIBGE, label: cidade.CIDADE })) || []);
        setFranquias([
          ...franquias,
          ...payload?.franquias.map((franquia: FranquiaInterface) => ({ value: franquia.EMP_CODIGO, label: franquia.EMP_NOMEFANTASIA })) || [],
        ]);

        setIsLoading(false);
      })
      .catch(handleError);
  }, []);

  return (
    <div className="modal fade" id="filtro" role="dialog" aria-labelledby="filtroLabel" aria-hidden="true" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Buscar ofertas...</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                    <form id="formFilter">
                      <div className="form-group">
                        <label>Tipos de Ofertas</label>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked
                              value="PADRAO"
                              onChange={(e) => filtersState.setFilters({ ...filtersState.filters, filterVP: e.target.checked ? 'PADRAO' : '' })}
                            />
                            <span className="form-check-sign"></span>
                            VP
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked
                              value="VPMIX"
                              onChange={(e) => filtersState.setFilters({ ...filtersState.filters, filterVPMIX: e.target.checked ? 'VPMIX' : '' })}
                            />
                            <span className="form-check-sign"></span>
                            VPMIX
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Ofertas dos Associados</label>
                        <div className="col-md-12">
                          <Select
                            isLoading={isLoading}
                            onChange={(e) => {
                              filtersState.setFilters({ ...filtersState.filters, filterFranchise: e });
                            }}
                            closeMenuOnSelect
                            isSearchable
                            options={franquias}
                            defaultValue={franquias[0]}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Segmentos</label>
                        <div className="col-md-12">
                          <Select
                            isLoading={isLoading}
                            onChange={(e) => {
                              filtersState.setFilters({ ...filtersState.filters, filterSegment: e });
                            }}
                            closeMenuOnSelect={false}
                            placeholder="Todos"
                            isMulti
                            isSearchable
                            options={segmentos}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Cidades</label>
                        <div className="col-md-12">
                          <Select
                            isLoading={isLoading}
                            onChange={(e, a) => {
                              filtersState.setFilters({ ...filtersState.filters, filterCity: e });
                            }}
                            closeMenuOnSelect={false}
                            placeholder="Todas"
                            isMulti
                            isSearchable
                            options={cidades}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button type="submit" className="btn btn-default btn-wd" data-dismiss="modal" onClick={fetchOffers} form="formFilter">
              <i className="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModalFilterAvailableOffers;
