import api from '~/config/api';

export const getEquipesAssociados = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('empCodigo', id);

    const { data } = await api.get(`/api/carteira-equipe/listar?${params.toString()}`);
    return data.payload;
  } catch (error) {
    return { success: false, error };
  }
};

export const save = async (payload) => {
  try {
    const { data, status } = await api.post('/api/carteira-equipe/salvar', payload);
    return { success: status === 200, data };
  } catch (error) {
    return { success: false, error };
  }
};

export const remove = async (payload) => {
  try {
    const { data, status } = await api.post(`/api/carteira-equipe/excluir`, payload);
    return { success: status === 200, data };
  } catch (error) {
    return { success: false, error };
  }
};

export const getEquipesGestores = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('empCodigo', id);

    const { data } = await api.get(`/api/carteira-gerencia/listar?${params.toString()}`);
    return data.payload;
  } catch (error) {
    return { success: false, error };
  }
};
export const saveGestor = async (payload) => {
  try {
    const { data, status } = await api.post('/api/carteira-gerencia/salvar', payload);
    return { success: status === 200, data };
  } catch (error) {
    return { success: false, error };
  }
};

export const removeGestor = async (payload) => {
  try {
    const { data, status } = await api.post(`/api/carteira-gerencia/excluir`, payload);
    return { success: status === 200, data };
  } catch (error) {
    return { success: false, error };
  }
};
