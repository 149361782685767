import React, { useContext, useEffect } from 'react';
import './styles.css';
import { ModalLoginPaymentForm, Offer } from '~/types';

import Header from '~/components/header';
import Footer from '~/components/footer';

import { baseURL } from '~/config/api';
import {
  closeModal,
  correctNumberWithComma,
  formatNumberToMoney,
  formatNumberToVPS,
  formatNumberWithoutPrefix,
  openItauShopline,
  openModal,
} from '~/utils/util';
import ModalCarousel from '~/components/modal/ModalCarousel';
import { useState } from 'react';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import LoadingPointsSpin from '~/components/loading/PageLoading';
import { getOfferDetails } from '~/service/OfferService';
import { useParams } from 'react-router-dom';
import { OfferSituation, OfferType } from '~/enum';
import ModalLoginPayment from '~/components/modal/ModalLoginPayment';
import { loginAndPermute } from '~/service/NegotiationService';
import logoInLine from '~/components/header/logo.png';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AuthContext } from '~/context/AuthContext';

const CompartilharOferta: React.FunctionComponent = () => {
  const urlParams = useParams<{ ofe_codigo: string }>();

  const { company: empresa } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [offer, setOffer] = useState<Offer>({} as Offer);
  const [sweetAlertBuyToday, setSweetAlertBuyToday] = useState<JSX.Element>();

  useEffect(() => {
    if (Number.isInteger(+urlParams.ofe_codigo)) fetchOfferDetails(urlParams.ofe_codigo);
  }, []);

  const fetchOfferDetails = async (offerId: string) => {
    setIsLoading(true);
    const { payload } = await getOfferDetails(offerId);
    setOffer(payload.oferta);

    setIsLoading(false);
  };

  const handlePermuteOnClick = async (offer: Offer) => {
    openModal('ModalLoginPayment');
  };

  const HandlePermute = async (formInput: ModalLoginPaymentForm, check_compra_hoje: boolean = true) => {
    const { payload, success, message, type } = await loginAndPermute({
      ofe_codigo: offer.OFE_CODIGO,
      ...formInput,
      fpa_descricao: formInput.fpa_descricao,
      one_descricao: formInput.one_descricao,
      check_compra_hoje: check_compra_hoje,
    });

    if (!success && type === 'teveCompraHoje') {
      openSweetAlertBuyToday(formInput);
      return false;
    } else if (!success) {
      showAlertDanger({ message });
      return false;
    } else {
      if (offer.OFE_ACOMBINAR === 'N') {
        openItauShopline(payload.dados);
        showAlertSuccess({ message: 'Permuta realizada com sucesso.', timeout: 0 });
      } else {
        showAlertSuccess({ message: 'Orçamento solicitado com sucesso.' });
      }
      closeModal('ModalLoginPayment');
      return true;
    }
  };

  const openSweetAlertBuyToday = (formInput: ModalLoginPaymentForm) => {
    setSweetAlertBuyToday(
      <SweetAlert
        warning
        title="Atenção!"
        onConfirm={() => {
          closeSweetAlertBuyToday();
          HandlePermute(formInput, false);
        }}
        onCancel={() => closeSweetAlertBuyToday()}
        allowEscape={true}
        showCancel
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Sim"
        cancelBtnText="Não"
      >
        <>
          Você ja realizou a compra desta oferta hoje!
          <br />
          Deseja comprar novamente?
        </>
      </SweetAlert>
    );
  };

  const closeSweetAlertBuyToday = () => {
    setSweetAlertBuyToday(undefined);
  };

  return (
    <>
      <div className="wrapper">
        <div className="main-panel" style={{ width: '100%' }}>
          <Header
            title={<img src={logoInLine} style={{ height: '50px' }} />}
            titleTag={offer.OFE_TITULO}
            hideNavItens
          />

          <div className="content">
            {isLoading && <LoadingPointsSpin />}
            <div className="container-fluid">
              <div className="row">
                <div className="col-0 col-md-1 col-lg-2" />
                <div className="col-12 col-md-10 col-lg-8">
                  <div className="card" style={{ borderRadius: '10px' }}>
                    <div className="card-header" style={{ borderRadius: '10px' }}>
                      <h4 className="card-title">
                        {/* Oferta disponível */}
                        {offer.OFE_TITULO}
                      </h4>
                      <p className="card-category">Separamos esta oferta para você!</p>
                    </div>

                    <div className="card-body">
                      <div className="details-offer">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {isLoading ? (
                            <div
                              style={{
                                width: 200,
                                height: '170px',
                                textAlign: 'center',
                                lineHeight: '170px',
                                backgroundColor: '#9a9a9a59',
                              }}
                            >
                              <i className="fas fa-spinner" />
                            </div>
                          ) : (
                            <>
                              <img
                                className="details-offer-main-image"
                                data-toggle="modal"
                                data-target="#carousel-modal"
                                src={
                                  offer.OFE_IMAGEM !== ''
                                    ? `${baseURL}/web/uploads/oferta/imagem/${offer.OFE_IMAGEM}`
                                    : `${baseURL}/web/uploads/logoLoja/${offer.EMP_LOGOTIPO}`
                                }
                              />
                              <ModalCarousel
                                imagesSRC={
                                  offer.OFE_IMAGEMS && offer.OFE_IMAGEMS.length > 0
                                    ? offer.OFE_IMAGEMS.map(
                                        (image) => baseURL + '/web/uploads/oferta/imagem/' + image.FILENAME
                                      )
                                    : [`${baseURL}/web/uploads/logoLoja/${offer.EMP_LOGOTIPO}`]
                                }
                              />
                            </>
                          )}

                          <button
                            type="button"
                            className="btn btn-warning btn-wd btn-outline"
                            data-toggle="modal"
                            data-target="#carousel-modal"
                          >
                            Ver fotos
                          </button>
                          {offer.OFE_STATUS == OfferSituation.LIBERADA && empresa.ITI_CODIGO != '6' && (
                            <button
                              type="button"
                              disabled={empresa.ITI_CODIGO == '6'}
                              className="btn btn-warning btn-wd"
                              data-dismiss="modal"
                              onClick={() => handlePermuteOnClick(offer)}
                            >
                              <i className="fa fa-gavel"></i>{' '}
                              {offer.OFE_ACOMBINAR === 'S' ? 'Solicitar orçamento' : 'Permutar'}
                            </button>
                          )}
                        </div>
                        <div className="details-offer-container-list">
                          {isLoading ? (
                            <div />
                          ) : (
                            <>
                              <div className="details-offer-list-item">
                                <p>
                                  <strong>Associado: </strong> <span>{offer.EMP_NOMEFANTASIA}</span>
                                </p>
                                <p>
                                  <strong>Cidade: </strong>
                                  <span>{offer.LEN_CIDADE}</span>
                                </p>
                                <p>
                                  <strong>VPMIX: </strong>{' '}
                                  <span>{offer.OFE_TIPO === OfferType.VPMIX ? 'Sim' : 'Não'}</span>
                                </p>
                                <p>
                                  <strong>Franquia: </strong> <span>{offer.MATRIZ_NOMEFANTASIA}</span>
                                </p>
                              </div>
                              <div className="details-offer-list-item">
                                <p>
                                  <strong>Promoção: </strong> <span>{offer.OFE_PROMOCAO === 'S' ? 'Sim' : 'Não'}</span>
                                </p>
                                <p>
                                  <strong>Valido até: </strong> <span>{offer.OFE_PRAZORESGATE}</span>
                                </p>
                                <p>
                                  <strong>Bem Ofertado: </strong> <span>{offer.BOF_DESCRICAO}</span>
                                </p>
                                <p>
                                  <strong>Qtde: </strong>{' '}
                                  <span>{offer.OFE_QTDOFERTADOS === '' ? <>&infin;</> : offer.OFE_QTDOFERTADOS}</span>
                                </p>
                              </div>
                              <div className="details-offer-list-item">
                                <p>
                                  <strong>Valor: </strong>
                                  <span>
                                    {offer.OFE_VALOR === undefined
                                      ? ''
                                      : offer.OFE_ACOMBINAR === 'S'
                                      ? 'À Combinar'
                                      : formatNumberToVPS(correctNumberWithComma(offer.OFE_VALOR))}
                                  </span>
                                </p>
                                {offer.OFE_TIPO === OfferType.VPMIX && (
                                  <>
                                    <p>
                                      <strong>Valor VPMIX: </strong>
                                      <span>
                                        {offer.OFE_VALOR === undefined
                                          ? ''
                                          : offer.OFE_ACOMBINAR === 'S'
                                          ? 'À Combinar'
                                          : formatNumberToMoney(correctNumberWithComma(offer.OFE_VALORVPMIX))}
                                      </span>
                                    </p>
                                    <p>
                                      <strong>Valor Total: </strong>
                                      <span>
                                        {offer.OFE_VALOR === undefined
                                          ? ''
                                          : offer.OFE_ACOMBINAR === 'S'
                                          ? 'À Combinar'
                                          : formatNumberWithoutPrefix(correctNumberWithComma(offer.OFE_VALORTOTAL))}
                                      </span>
                                    </p>
                                  </>
                                )}
                              </div>
                              <div className="details-offer-list-item">
                                <p>
                                  <strong>Descrição: </strong>{' '}
                                  <span style={{ whiteSpace: 'pre-line' }}>{offer.OFE_DESCRICAO}</span>
                                </p>
                              </div>
                              <div className="details-offer-list-item">
                                <p>
                                  <strong>Observação: </strong>{' '}
                                  <span style={{ whiteSpace: 'pre-line' }}>{offer.OFE_OBSERVACAO}</span>
                                </p>
                              </div>
                              <div className="details-offer-list-item">
                                <p>
                                  <strong>Documentos: </strong>{' '}
                                  <span>
                                    {
                                      <a
                                        target="_blank"
                                        href={`${baseURL}/web/uploads/oferta/documento/${offer.OFE_DOCUMENTO}`}
                                      >
                                        {offer.OFE_DOCUMENTO}
                                      </a>
                                    }
                                  </span>
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-0 col-md-1 col-lg-2" />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ModalLoginPayment onSubmit={HandlePermute} offer={offer} />
      {sweetAlertBuyToday}
    </>
  );
};

export default CompartilharOferta;
