import React, { useContext, useEffect, useRef, useState } from 'react';
import { GoodOffered, Image, ModalCreateOfferProps, Offer, OptionProps } from '~/types';
import AsyncSelect from 'react-select/async';
import { showAlertDanger, showAlertSuccess, showAlertWarning } from '~/components/notification';
import { AuthContext } from '~/context/AuthContext';
import {
  correctNumberWithComma,
  formatDateToDefaultPattern,
  formatNumberToMoney,
  formatNumberToVPS,
  formatNumberWithoutPrefix,
  getKeys,
} from '~/utils/util';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { baseURL } from '~/config/api';
import LoadingFormSpin from '~/components/loading/FormSpin';
import { EmpType, OfferSituation, BasicResponse, OfferType } from '~/enum';
import BasicInput from '~/components/input/BasicInput';
import BasicTextarea from '~/components/input/BasicTextarea';
import ControlledSelect from '~/components/input/ControlledSelect';
import ControlledMoneyFormatter from '~/components/input/ControlledMoneyFormatter';
import { autoCompleteAssociado, finishOffer, saveOffer } from '~/service/OfferService';
import ImageDropzone from './ImageDropzone';
import moment from 'moment';
import { registerErrorSystem } from '~/service/SystemService';

interface FormInput {
  ofe_titulo: string;
  ofe_tipo: OfferType;
  ofe_acombinar: BasicResponse;
  ofe_valor?: number;
  ofe_valortotal?: number;
  ofe_valorvpmix?: number;
  ofe_status: OfferSituation;
  bof_codigo: number;
  ofe_qtdofertados: number;
  ofe_dataexpiracao?: Date;
  ofe_prazoresgate?: Date;
  fpa_codigo?: number;
  ofe_fracionada: BasicResponse;
  ofe_qtdfracionada?: number;
  ofe_valorfracionado?: number;
  ofe_direcionada: BasicResponse;
  ofe_codassociado?: OptionProps;
  ofe_pendencia?: string;
  ofe_descricao: string;
  ofe_observacao: string;
  newArquivosDoc: any;
  ofe_imagem?: string;
}

const status = [
  { value: 'A', label: 'Aberta' },
  { value: 'B', label: 'Bloqueada' },
  { value: 'D', label: 'Liberada' },
  { value: 'P', label: 'Pendente' },
  { value: 'E', label: 'Encerrada' },
  { value: 'R', label: 'Reenviado' },
];

const offerTypes = [
  { value: 'PADRAO', label: 'VP' },
  { value: 'VPMIX', label: 'VPMIX' },
];

const toCombineOptions = [
  { value: 'N', label: 'Não' },
  { value: 'S', label: 'Sim' },
];

const ModalCreateOffer: React.FC<ModalCreateOfferProps> = ({ offer, isLoading, config, fetchMyOffers, onConfirm }) => {
  const { user: usuario, company: empresa } = useContext(AuthContext);

  const validationSchema: yup.SchemaOf<FormInput> = yup.object().shape({
    ofe_titulo: yup.string().required().min(1),
    ofe_tipo: yup.mixed<OfferType>().required(),
    ofe_acombinar: yup.mixed<BasicResponse>().required(),

    ofe_valor: yup
      .number()
      .transform((_, val) => (val === '' ? 0 : val))
      .when('ofe_acombinar', {
        is: 'N',
        then: yup.number().moreThan(0).required(),
        otherwise: yup.number().oneOf([0]),
      }),

    ofe_valortotal: yup
      .number()
      .transform((_, val) => (val === '' ? 0 : val))
      .when('ofe_tipo', {
        is: 'VPMIX',
        then:
          config.config_vpmix && config.config_vpmix.EMP_QTDMINIMAOFERTAVPMIX !== ''
            ? yup
                .number()
                .min(+config.config_vpmix.EMP_QTDMINIMAOFERTAVPMIX)
                .required()
            : yup.number().required(),
        otherwise: yup.number().oneOf([0]),
      }),
    ofe_valorvpmix: yup
      .number()
      .transform((_, val) => (val === '' ? 0 : val))
      .when('ofe_tipo', {
        is: 'VPMIX',
        then:
          config.config_vpmix && config.config_vpmix.EMP_PERCMAXREAISOFERTAVPMIX !== ''
            ? yup.number().when('ofe_valortotal', (ofe_valortotal: number) =>
                yup
                  .number()
                  .max(+ofe_valortotal * (+config.config_vpmix.EMP_PERCMAXREAISOFERTAVPMIX / 100))
                  .required()
              )
            : yup.number().required(),
        otherwise: yup.number().oneOf([0]),
      }),

    ofe_status: yup.mixed<OfferSituation>().required(),
    bof_codigo: yup.number().required(),

    ofe_qtdofertados: yup
      .number()
      .required()
      .when('ofe_direcionada', { is: 'S', then: yup.number().oneOf([1]), otherwise: yup.number().min(1) }),

    ofe_dataexpiracao: yup.date().required(),
    ofe_prazoresgate: yup.date().required(),

    fpa_codigo: yup.number(),

    ofe_fracionada: yup.mixed<BasicResponse>().required(),
    ofe_qtdfracionada: yup
      .number()
      .transform((_, val) => (val === '' ? undefined : val))
      .when('ofe_fracionada', {
        is: 'S',
        then: yup.number().required(),
        otherwise: yup.number(),
      }),
    ofe_valorfracionado: yup
      .number()
      .transform((_, val) => (val === '' ? null : val))
      .when('ofe_fracionada', { is: 'S', then: yup.number().required(), otherwise: yup.number().nullable() }),

    ofe_direcionada: yup.mixed<BasicResponse>().required(),
    ofe_codassociado: yup.mixed<OptionProps>().when('ofe_direcionada', {
      is: 'S',
      then: yup.mixed<OptionProps>().test((option) => option !== undefined && option.value !== ''),
    }),

    ofe_pendencia: yup.string().when('ofe_status', { is: OfferSituation.PENDENTE, then: yup.string().required() }),

    ofe_descricao: yup.string().required(),
    ofe_observacao: yup.string().required(),
    newArquivosDoc: yup.mixed(),
    ofe_imagem: yup.string(),
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  });

  const modelRef = useRef<HTMLDivElement>(null);
  const [bensOfertados, setBensOfertados] = useState([] as OptionProps[]);
  const [formasPgto, setFormasPgto] = useState([] as OptionProps[]);
  const [images, setImages] = useState<Image[]>([]);
  const [removedImages, setRemovedImages] = useState<string[]>([]);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [removeOldDoc, setRemoveOldDoc] = useState(false);

  useEffect(() => {
    modelRef.current?.addEventListener('focus', overflowOnFocus);
    return () => {
      modelRef.current?.removeEventListener('focus', overflowOnFocus);
    };
  }, []);

  useEffect(() => {
    offer && offer.OFE_IMAGEMS.length > 0
      ? setImages(
          offer.OFE_IMAGEMS.map((image) => ({
            name: image.FILENAME,
            label: image.FILENAME,
            preview: baseURL + '/web/uploads/oferta/imagem/' + image.FILENAME,
            isNew: false,
          }))
        )
      : setImages([]);

    setRemovedImages([]);
    setRemoveOldDoc(false);
  }, [offer]);

  useEffect(() => {
    if (!isLoading) {
      setBensOfertados(
        config.segmentos.map((segmento: GoodOffered) => ({ value: segmento.BOF_CODIGO, label: segmento.BOF_DESCRICAO }))
      );
      setFormasPgto(
        config.formasPgto.map((paymentOption) => ({
          value: paymentOption.FPA_CODIGO,
          label: paymentOption.FPA_DESCRICAO,
        }))
      );
    }
  }, [isLoading, config]);

  const onSubmit = async (formValues: FormInput): Promise<void> => {
    setIsAwaitResponse(true);

    const formData = getKeys(formValues).reduce((formData, key) => {
      const auxValue = formValues[key];
      const value = auxValue === undefined || auxValue === null ? '' : auxValue.toString();

      formData.append(key, value);
      return formData;
    }, new FormData());

    empresa.EMP_TIPO === EmpType.FRANQUIA && formData.set('emp_tipo', 'F');

    formData.set('fpa_codigo', '');

    formData.set('ofe_associado', formValues.ofe_codassociado?.label || '');
    formData.set('ofe_codassociado', formValues.ofe_codassociado?.value.toString() || '');

    formData.set('ofe_prazoresgate', moment(formValues.ofe_prazoresgate).format('YYYY-MM-DD'));
    formData.set('ofe_dataexpiracao', moment(formValues.ofe_dataexpiracao).format('YYYY-MM-DD'));

    formData.set('ofe_codigo', offer === null ? '' : offer.OFE_CODIGO);

    formData.set('usu_codigo', usuario.USU_CODIGO.toString());
    formData.set('emp_codigo', offer === null ? empresa.EMP_CODIGO.toString() : offer.EMP_CODIGO);
    formData.set('emp_codmatriz', empresa.EMP_CODMATRIZ.toString());
    formData.set('emp_razaosocial', empresa.EMP_RAZAOSOCIAL.toString());

    if (offer?.isCopy === 'S') {
      formData.set('ofe_copia', offer?.isCopy || 'N');
      formData.set('ofe_imagem', formValues.ofe_imagem?.split('_').pop() || '');

      !removeOldDoc && formData.set('docToCopy', offer?.OFE_DOCUMENTO);

      images
        .filter((image) => !image.isNew)
        .forEach((image, i) => {
          formData.set(`imagesToCopy[${i}]`, image.name || '');
        });
    }

    offer?.isCopy !== 'S' &&
      removeOldDoc &&
      offer?.OFE_DOCUMENTO !== '' &&
      formData.set('tabelaArquivosDoc', offer?.OFE_DOCUMENTO || '');
    formData.set('newArquivosDoc', formValues.newArquivosDoc[0]);

    offer?.isCopy !== 'S' && removedImages.forEach((rm, i) => formData.set(`imagesToRemove[${i}]`, rm));
    images
      .filter((image) => image.isNew)
      .forEach((image, i) => {
        formData.set(`imagesToAddName[${i}]`, image.name || '');
        formData.set(`imagesToAdd[${i}]`, image.file || '');
      });

    const response = await saveOffer(formData);
    const { success, message, type, payload } = response;

    if (!success) {
      registerErrorSystem(response);

      console.log(response);

      if (type === 'validationError') {
        showAlertDanger({ message: payload.erro });
        setIsAwaitResponse(false);
      } else if (message) showAlertDanger({ message });
      else if (payload?.erro) showAlertDanger({ message: payload.erro });
      else showAlertDanger({ message: 'Ocorreu um erro inesperado. Tente novamente' });

      if (type !== 'validationError') {
        $('#criarOferta').modal('hide');
        reset();
        fetchMyOffers();
      }
    } else if (!payload.imagesResult.success) {
      showAlertWarning({ message: payload.imagesResult.message });
      onConfirm(payload.oferta);
      fetchMyOffers();
    } else if (success) {
      setIsAwaitResponse(false);
      showAlertSuccess({ message: offer ? 'Oferta atualizada com sucesso' : 'Oferta criada com sucesso' });
      fetchMyOffers();
      $('#criarOferta').modal('hide');
      reset();
    }
  };

  const handleFinishOffer = async () => {
    setIsAwaitResponse(true);
    const { success, message } = await finishOffer(offer?.OFE_CODIGO);
    setIsAwaitResponse(false);

    if (success) {
      showAlertSuccess({ message: 'Oferta encerrada.' });
      fetchMyOffers();
      $('#criarOferta').modal('hide');
    } else {
      message
        ? showAlertDanger({ message })
        : showAlertDanger({ message: 'Ocorreu um erro inesperado. Tente novamente' });
    }
  };

  const validateTotalValue = (_: React.FocusEvent<HTMLInputElement>, value: number) => {
    if (value < +config.config_vpmix.EMP_QTDMINIMAOFERTAVPMIX) {
      showAlertWarning({
        message: `Para esta oferta VPMIX, o valor total da oferta deve ser igual ou superior a ${formatNumberWithoutPrefix(
          +config.config_vpmix.EMP_QTDMINIMAOFERTAVPMIX
        )}`,
        timeout: 10,
      });
      setValue('ofe_valortotal', +config.config_vpmix.EMP_QTDMINIMAOFERTAVPMIX);
    }
  };

  const validateVPMIXValue = (_: React.FocusEvent<HTMLInputElement>, value: number) => {
    const total = Number(watch('ofe_valortotal'));
    const percent = (value / total) * 100;

    if (percent > +config.config_vpmix.EMP_PERCMAXREAISOFERTAVPMIX) {
      const correctValue = total * (+config.config_vpmix.EMP_PERCMAXREAISOFERTAVPMIX / 100);
      showAlertWarning({
        message: `Para esta oferta VPMIX, o valor em R$ deve ser igual ou inferior a ${formatNumberToMoney(
          correctValue
        )}`,
        timeout: 10,
      });
      setValue('ofe_valorvpmix', correctValue);
    }
  };

  const overflowOnFocus = () => {
    document.body.classList.add('modal-open');
    setIsAwaitResponse(false);
  };

  return (
    <div
      className="modal fade"
      id="criarOferta"
      role="dialog"
      aria-labelledby="criarOfertaLabel"
      aria-hidden="true"
      tabIndex={-1}
      ref={modelRef}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">{offer?.OFE_CODIGO ? 'Editando Oferta' : 'Criando Oferta'}</h4>
                  </div>
                  <div className="card-body ">
                    <form
                      key={offer?.OFE_CODIGO + 'genericFormId'}
                      id="genericFormId"
                      onSubmit={handleSubmit(onSubmit, console.log)}
                    >
                      {/**
                       * Sessão
                       *
                       */}
                      <ImageDropzone
                        offer={offer}
                        images={images}
                        setImages={setImages}
                        removedImages={removedImages}
                        setRemovedImages={setRemovedImages}
                      />
                      <ControlledSelect
                        label="Imagem de capa da oferta"
                        name="ofe_imagem"
                        hasError={!!errors.ofe_imagem}
                        control={control}
                        defaultValue={offer ? offer.OFE_IMAGEM : ''}
                        options={[
                          { value: '', label: 'Igual a logomarca da empresa' },
                          ...images.map((option) => ({ value: option.name, label: option.label })),
                        ]}
                      />
                      <BasicInput
                        label="Título"
                        hasError={!!errors.ofe_titulo}
                        isRequired
                        // ref={register}
                        control={control}
                        name="ofe_titulo"
                        type="text"
                        placeholder="Título da oferta"
                        defaultValue={offer?.OFE_TITULO}
                      />
                      {/**
                       * Sessão
                       *
                       */}
                      <ControlledSelect
                        label="Tipo"
                        name="ofe_tipo"
                        hasError={!!errors.ofe_tipo}
                        isRequired
                        isLoading={isLoading}
                        isDisabled={isLoading || config.config_vpmix.EMP_STATUSVPMIX !== 'Ativo'}
                        control={control}
                        options={offerTypes}
                        defaultValue={offer === null ? 'PADRAO' : offer.OFE_TIPO}
                      />
                      <ControlledSelect
                        label="Valor à combinar"
                        isRequired
                        hasError={!!errors.ofe_acombinar}
                        keyComponent={watch('ofe_tipo') + 'ofe_acombinar'}
                        name="ofe_acombinar"
                        control={control}
                        defaultValue={offer === null ? 'N' : offer.OFE_ACOMBINAR}
                        isDisabled={watch('ofe_tipo') === 'VPMIX' || empresa.EMP_TIPO === EmpType.FRANQUIA}
                        options={toCombineOptions}
                      />
                      {watch('ofe_tipo') === 'PADRAO' ? (
                        <ControlledMoneyFormatter
                          label="Valor em VP$"
                          hasError={!!errors.ofe_valor}
                          isRequired
                          keyComponent={watch('ofe_acombinar') + 'ofe_valor'}
                          name="ofe_valor"
                          control={control}
                          defaultValue={
                            watch('ofe_acombinar') === 'S' ? 0 : offer ? correctNumberWithComma(offer.OFE_VALOR) : 0
                          }
                          readOnly={watch('ofe_acombinar') === 'S'}
                          prefix="VP$"
                          placeholder="VP$"
                          isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                        />
                      ) : isLoading ? (
                        <>
                          <div className="form-group form-loader">
                            <label>
                              Valor Total da Oferta
                              <span className="star" />
                            </label>
                            <input type="text" className="form-control" disabled placeholder="R$ + VP$" />
                            <LoadingFormSpin />
                          </div>
                          <div className="form-group form-loader">
                            <label>
                              Valor em VP$
                              <span className="star" />
                            </label>
                            <input type="text" className="form-control" disabled placeholder="VP$" />
                            <LoadingFormSpin />
                          </div>
                          <div className="form-group form-loader">
                            <label>
                              Valor em R$
                              <span className="star" />
                            </label>
                            <input type="text" className="form-control" disabled placeholder="R$" />
                            <LoadingFormSpin />
                          </div>
                        </>
                      ) : (
                        <>
                          <ControlledMoneyFormatter
                            label="Valor Total da Oferta"
                            isRequired
                            hasError={!!errors.ofe_valortotal}
                            keyComponent={watch('ofe_acombinar') + 'ofe_valortotal'}
                            name="ofe_valortotal"
                            control={control}
                            defaultValue={
                              watch('ofe_acombinar') === 'S'
                                ? undefined
                                : offer
                                ? correctNumberWithComma(offer.OFE_VALORTOTAL)
                                : undefined
                            }
                            readOnly={watch('ofe_acombinar') === 'S'}
                            prefix=""
                            onBlur={validateTotalValue}
                            placeholder="R$ + VP$"
                            isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                          />
                          <div className={`form-group ${errors.ofe_valor ? 'has-error' : 'has-success'}`}>
                            <label>
                              Valor em VP$
                              <span className="star" />
                            </label>
                            <Controller
                              name="ofe_valor"
                              control={control}
                              defaultValue={''}
                              render={({ onChange }) => (
                                <NumberFormat
                                  readOnly
                                  format={(e) => formatNumberToVPS(+e / 100)}
                                  onValueChange={(e) => onChange(+e.value / 100)}
                                  className="form-control"
                                  placeholder="VP$"
                                  displayType={'input'}
                                  value={Number(watch('ofe_valortotal')) * 100 - Number(watch('ofe_valorvpmix')) * 100}
                                  disabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                                />
                              )}
                            />
                          </div>
                          <ControlledMoneyFormatter
                            label="Valor em R$"
                            isRequired
                            placeholder="VP$"
                            hasError={!!errors.ofe_valorvpmix}
                            keyComponent={watch('ofe_valortotal') + 'ofe_valorvpmix'}
                            name="ofe_valorvpmix"
                            prefix="R$"
                            control={control}
                            defaultValue={
                              watch('ofe_acombinar') === 'S'
                                ? undefined
                                : offer === null
                                ? Number(watch('ofe_valortotal')) *
                                  (+config.config_vpmix.EMP_PERCMAXREAISOFERTAVPMIX / 100)
                                : Number(watch('ofe_valortotal')) === correctNumberWithComma(offer.OFE_VALORTOTAL)
                                ? correctNumberWithComma(offer.OFE_VALORVPMIX)
                                : Number(watch('ofe_valortotal')) *
                                  (+config.config_vpmix.EMP_PERCMAXREAISOFERTAVPMIX / 100)
                            }
                            readOnly={watch('ofe_acombinar') === 'S'}
                            onBlur={validateVPMIXValue}
                            isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                          />
                        </>
                      )}
                      {/**
                       * Sessão
                       *
                       */}
                      <ControlledSelect
                        label="Situação"
                        isRequired
                        hasError={!!errors.ofe_status}
                        name="ofe_status"
                        control={control}
                        defaultValue={offer ? offer.OFE_STATUS : 'A'}
                        isDisabled={empresa.EMP_TIPO !== EmpType.FRANQUIA}
                        options={status}
                      />
                      <ControlledSelect
                        label="Bem Ofertado"
                        isRequired
                        hasError={!!errors.bof_codigo}
                        name="bof_codigo"
                        control={control}
                        defaultValue={offer ? offer.BOF_CODIGO : ''}
                        options={bensOfertados}
                        isLoading={isLoading}
                      />
                      <BasicInput
                        label="Quantidade Ofertada"
                        hasError={!!errors.ofe_qtdofertados}
                        // ref={register}
                        control={control}
                        name="ofe_qtdofertados"
                        type="number"
                        defaultValue={offer ? offer.OFE_QTDOFERTADOS : undefined}
                      />
                      <BasicInput
                        label="Expira em"
                        type="date"
                        hasError={!!errors.ofe_dataexpiracao}
                        isRequired
                        defaultValue={offer ? formatDateToDefaultPattern(offer.OFE_DATAEXPIRACAO).split(' ')[0] : ''}
                        // ref={register}
                        control={control}
                        name="ofe_dataexpiracao"
                        isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                      />
                      <BasicInput
                        label="Voucher Válido até"
                        type="date"
                        hasError={!!errors.ofe_prazoresgate}
                        isRequired
                        defaultValue={offer ? formatDateToDefaultPattern(offer.OFE_PRAZORESGATE) : ''}
                        // ref={register}
                        control={control}
                        name="ofe_prazoresgate"
                        isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                      />
                      {/* <ControlledSelect
                        label="Pagamento Comissão"
                        isRequired
                        hasError={!!errors.fpa_codigo}
                        name="fpa_codigo"
                        control={control}
                        defaultValue={offer ? offer?.FPA_CODIGO : ''}
                        options={formasPgto}
                        isLoading={isLoading}
                      /> */}
                      <ControlledSelect
                        label="Fracionada"
                        isRequired
                        hasError={!!errors.ofe_fracionada}
                        name="ofe_fracionada"
                        control={control}
                        defaultValue={offer ? offer.OFE_FRACIONADA : 'N'}
                        options={toCombineOptions}
                        isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                      />
                      <BasicInput
                        label="Quantidade Fracionada"
                        isRequired={watch('ofe_fracionada') === 'S'}
                        hasError={!!errors.ofe_qtdfracionada}
                        // ref={register}
                        control={control}
                        name="ofe_qtdfracionada"
                        readOnly={watch('ofe_fracionada') === 'N'}
                        key={watch('ofe_fracionada') + 'ofe_qtdfracionada'}
                        type="number"
                        defaultValue={
                          watch('ofe_fracionada') === 'N' ? undefined : offer ? offer.OFE_QTDFRACIONADA : undefined
                        }
                        isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                      />
                      <ControlledMoneyFormatter
                        label="Valor Fracionado"
                        isRequired={watch('ofe_fracionada') === 'S'}
                        hasError={!!errors.ofe_valorfracionado}
                        key={watch('ofe_fracionada') + 'ofe_valorfracionado'}
                        name="ofe_valorfracionado"
                        control={control}
                        prefix="VP$"
                        defaultValue={
                          watch('ofe_fracionada') === 'N'
                            ? undefined
                            : offer
                            ? correctNumberWithComma(offer.OFE_VALORFRACIONADO)
                            : undefined
                        }
                        readOnly={watch('ofe_fracionada') === 'N'}
                        placeholder="VP$"
                        isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                      />
                      <ControlledSelect
                        label="Direcionada"
                        isRequired
                        hasError={!!errors.ofe_direcionada}
                        name="ofe_direcionada"
                        control={control}
                        defaultValue={offer ? offer.OFE_DIRECIONADA : 'N'}
                        options={toCombineOptions}
                        isDisabled={empresa.EMP_TIPO === EmpType.FRANQUIA}
                      />
                      <div className={`form-group ${errors.ofe_codassociado ? 'has-error' : 'has-success'}`}>
                        <label>
                          Associado direcionado{watch('ofe_direcionada') === 'S' && <span className="star" />}
                        </label>
                        <Controller
                          key={watch('ofe_direcionada') + 'ofe_codassociado'}
                          name="ofe_codassociado"
                          control={control}
                          defaultValue={
                            watch('ofe_direcionada') === 'N'
                              ? { value: '', label: '' }
                              : offer
                              ? { value: offer?.OFE_CODASSOCIADO, label: offer?.OFE_ASSOCIADO }
                              : { value: '', label: '' }
                          }
                          render={({ onChange, value }) => (
                            <AsyncSelect
                              className="react-select-container"
                              classNamePrefix="react-select"
                              isDisabled={watch('ofe_direcionada') === 'N' || empresa.EMP_TIPO === EmpType.FRANQUIA}
                              cacheOptions
                              noOptionsMessage={() => 'Digite no mínimo 3 letras...'}
                              defaultOptions
                              placeholder="Digite pelo menos 3 letras"
                              loadOptions={async (value, callback) => {
                                if (value.length < 3) return;
                                const response = await autoCompleteAssociado(value);
                                callback(
                                  response.map((row: any) => ({
                                    value: row.EMP_CODIGO,
                                    label: row.EMP_NOMEFANTASIA,
                                    cnpj: row.EMP_CPFCNPJ,
                                  }))
                                );
                              }}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                      {(offer?.OFE_STATUS === OfferSituation.PENDENTE ||
                        watch('ofe_status') === OfferSituation.PENDENTE) && (
                        <BasicTextarea
                          label="Pendência"
                          hasError={!!errors.ofe_pendencia}
                          isRequired
                          ref={register}
                          name="ofe_pendencia"
                          height="60px"
                          defaultValue={offer ? offer.OFE_PENDENCIA : ''}
                        />
                      )}

                      <BasicTextarea
                        label="Descrição"
                        isRequired
                        hasError={!!errors.ofe_descricao}
                        height="100px"
                        ref={register}
                        name="ofe_descricao"
                        defaultValue={offer ? offer.OFE_DESCRICAO : ''}
                      />

                      <BasicTextarea
                        label="Observação"
                        isRequired
                        hasError={!!errors.ofe_observacao}
                        height="60px"
                        ref={register}
                        name="ofe_observacao"
                        defaultValue={offer ? offer.OFE_OBSERVACAO : ''}
                      />
                      {offer && !removeOldDoc && offer?.OFE_DOCUMENTO !== '' && (
                        <div className="form-group">
                          <label>Documento anexado</label>
                          <div>
                            <span
                              style={{
                                width: 'calc(100% - 20px)',
                                display: 'inline-block',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <i className="fas fa-file-alt" />{' '}
                              <a
                                href={`${baseURL}/web/uploads/oferta/documento/${offer?.OFE_DOCUMENTO}`}
                                target="_blank"
                                style={{ textDecoration: 'underline', color: 'black' }}
                              >
                                {offer?.OFE_DOCUMENTO}
                              </a>
                            </span>
                            <span
                              title="Remover documento"
                              onClick={() => setRemoveOldDoc(true)}
                              style={{
                                width: '20px',
                                display: 'inline-block',
                                color: 'red',
                                textAlign: 'center',
                                overflow: 'hidden',
                                cursor: 'pointer',
                              }}
                            >
                              <i className="fas fa-times" />
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="form-group">
                        <label>Anexar novo documento</label>
                        <input
                          ref={register}
                          name="newArquivosDoc"
                          type="file"
                          className="form-control"
                          style={{ border: 'none' }}
                          onChange={(e) => e.target.files && setRemoveOldDoc(true)}
                          accept=".gif, .jpg, .jpeg, .png, .bmp, .doc, .xls, .pdf, .txt"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left" /> Voltar
            </button>
            {offer?.OFE_STATUS === OfferSituation.ENCERRADA ? (
              <div />
            ) : isAwaitResponse ? (
              <button
                className={`btn btn-wd ${
                  watch('ofe_status') === OfferSituation.LIBERADA ? 'btn-danger' : 'btn-success'
                }`}
              >
                <i className="fas fa-spinner rotating" /> Processando...
              </button>
            ) : offer?.OFE_STATUS === OfferSituation.LIBERADA ? (
              <button className={'btn btn-wd btn-danger'} onClick={handleFinishOffer}>
                <i className="fa fa-save" /> Encerrar
              </button>
            ) : (
              <button type="submit" className={'btn btn-wd btn-success'} form="genericFormId">
                <i className="fa fa-save" /> Salvar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateOffer;
