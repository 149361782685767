import api from '~/config/api';

export const getByCompany = async (id, s, e) => {
  try {
    const params = new URLSearchParams();
    params.append('empCodigo', id);
    if (s) params.append('inicio', s);
    if (e) params.append('fim', e);
    const { data } = await api.get(`/api/visitas/listar?${params.toString()}`);
    return data.payload;
  } catch (error) {
    return { success: false, error };
  }
};
export const save = async (payload) => {
  try {
    const { data, status } = await api.post('/api/visitas/salvar', payload);
    return { success: status === 200, data };
  } catch (error) {
    return { success: false, error };
  }
};

export const saveRetorno = async (payload) => {
  try {
    const { data, status } = await api.post('/api/visitas/salvarRetorno', payload);
    return { success: status === 200, data };
  } catch (error) {
    return { success: false, error };
  }
};
