import React from 'react'

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="container">
                <nav>
                    <ul className="footer-menu">
                        <li>
                            <a href="http://viapermuta.com.br/?fbclid=IwAR3w9WiR6__npbZFzfyspoH3Y0crju7gk1PnFAASrQ4BNe7sKp2mmT80AWg#quem-somos" target="_blank">
                                Quem somos
                            </a>
                        </li>
                        <li>
                            <a href="http://viapermuta.com.br/?fbclid=IwAR3w9WiR6__npbZFzfyspoH3Y0crju7gk1PnFAASrQ4BNe7sKp2mmT80AWg#como-funciona" target="_blank">
                                Como Funciona
                            </a>
                        </li>
                        <li>
                            <a href="http://viapermuta.com.br/?fbclid=IwAR3w9WiR6__npbZFzfyspoH3Y0crju7gk1PnFAASrQ4BNe7sKp2mmT80AWg#vantagens" target="_blank">
                                Vantagens
                            </a>
                        </li>
                        <li>
                            <a href="http://viapermuta.com.br/?fbclid=IwAR3w9WiR6__npbZFzfyspoH3Y0crju7gk1PnFAASrQ4BNe7sKp2mmT80AWg#associados" target="_blank">
                                Associados
                            </a>
                        </li>
                        <li>
                            <a href="http://viapermuta.com.br/?fbclid=IwAR3w9WiR6__npbZFzfyspoH3Y0crju7gk1PnFAASrQ4BNe7sKp2mmT80AWg#onde-estamos" target="_blank">
                                Onde Estamos
                            </a>
                        </li>
                    </ul>
                    <p className="copyright text-center">
                        ©
                        <a href="http://www.lakstech.com.br" target="_blank">Laks Tecnologia</a>, faça algo ótimo
                    </p>
                </nav>
            </div>
        </footer>
    )

}

export default Footer