import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import BasicInput from '~/components/input/BasicInput';

interface ModalProps {
  onSubmit: (data: any, reset: any) => void;
  isAwaitResponse: boolean;
  schema: any;
  retornos: any[] | never[];
}

const ModalRetorno: React.FC<ModalProps> = ({ schema, onSubmit, isAwaitResponse, retornos }) => {
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rva_titulo: '',
    },
  });

  return (
    <div className="modal fade" id="modalRetono" role="dialog" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body p-0">
            <form onSubmit={handleSubmit((data) => onSubmit(data, reset))} id="formIdRetorno">
              <div className="row">
                <div className="col-md-12">
                  <div className="card stacked-form">
                    <div className="card-header ">
                      <h4 className="card-title">Retorno ao Associado</h4>
                    </div>
                    <div className="card-body p-0">
                      <div className="p-3">
                        <BasicInput
                          isRequired
                          control={control}
                          type="text"
                          defaultValue={''}
                          hasError={!!errors.rva_titulo}
                          name="rva_titulo"
                          label="Descrição do Retorno"
                          placeholder="Descreva o tipo de retorno"
                        />
                      </div>

                      <hr className="mb-0" />
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Descrição</th>
                            <th>Situação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {retornos.map((retorno: any, i: number) => (
                            <tr key={i}>
                              <td>{retorno.RVA_TITULO}</td>
                              <td>{retorno.RVA_STATUS}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-success btn-wd" form="formIdRetorno" type="submit" disabled={isAwaitResponse}>
              {isAwaitResponse ? (
                <>
                  <i className="fas fa-spinner rotating" /> Processando...
                </>
              ) : (
                <>
                  <i className="fa fa-save" /> Salvar
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRetorno;
