import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Text,
  LabelList,
  Cell,
  ReferenceLine,
  PieChart,
  Pie,
  Legend,
} from 'recharts';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';
import IndicatorCard from '~/components/card/IndicatorCard';
import {
  abbreviateNumber,
  compareStrings,
  correctNumberWithComma,
  formatNumberToVPS,
  formatNumberWithoutPrefix,
  getMonth,
} from '~/utils/util';
import PageLoading from '~/components/loading/PageLoading';
import { showAlertWarning } from '~/components/notification';
import TableWithLinks from '~/components/table/TableWithLinks';
import {
  UltimasNegociacoesFranquia,
  graphData,
  graphData02,
  graphData04,
  graphData05,
  graphDataDefault,
  top10AssociadosInterface,
  ticketMedioInterface,
} from './dashboardTypes';
import { ViewDashboardProps } from '~/types';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#413ea0', '#82ca9d'];

const ViewDashboard: React.FunctionComponent<ViewDashboardProps> = ({
  isLoadingState: { isLoading, setIsLoading },
  handleFetchDashboard,
  filterStartDate,
  changeFilterStartDate,
  filterEndDate,
  changeFilterEndDate,
  startDate,
  endDate,
  headerAppend,
}) => {
  const [cardSells, setCardSells] = useState('');
  const [cardTicketBuy, setCardTicketBuy] = useState('');
  const [cardTicketSell, setCardTicketSell] = useState('');
  const [cardOfferCount, setCardOfferCount] = useState('');
  const [totalCurrentMonth, setTotalCurrentMonth] = useState('');
  const [totalCurrentMonthVariation, setTotalCurrentMonthVariation] = useState('');
  const [tradingCurrentMonth, setTradingCurrentMonth] = useState('');
  const [tradingCurrentMonthVariation, setTradingCurrentMonthVariation] = useState('');

  const [totalVendasXMes, setTotalVendasXMes] = useState<graphDataDefault[]>([]);
  const [ticketMedio, setTicketMedio] = useState<ticketMedioInterface[]>([]);
  const [totalTransacaoXMes, setTotalTransacaoXMes] = useState<graphDataDefault[]>([]);
  const [top10AssociadosType, setTop10AssociadosType] = useState<'nTransactions' | 'valueTransactions'>(
    'valueTransactions'
  );
  const [perfilDeTransacoes, setPerfilDeTransacoes] = useState<graphDataDefault[]>([]);
  const [saldoRede, setSaldoRede] = useState<graphDataDefault[]>([]);
  const [ultimasNegociacoes, setUltimasNegociacoes] = useState<UltimasNegociacoesFranquia[]>([]);
  const [top10Associados, setTop10Associados] = useState<top10AssociadosInterface>({
    nTransactions: [],
    valueTransactions: [],
  });

  const [isGrid, setIsGrid] = useState<boolean>(false);

  useEffect(() => {
    fetchDashboard(false);
  }, []);

  const fetchDashboard = async (onlyCards: boolean = false) => {
    if (!onlyCards) {
      setTotalCurrentMonth('');
      setTotalCurrentMonthVariation('');
      setTradingCurrentMonth('');
      setTradingCurrentMonthVariation('');
      setCardOfferCount('');
    }

    setIsLoading(true);
    setCardSells('');
    setCardTicketBuy('');
    setCardTicketSell('');

    const {
      totalVendasXMes,
      ticketMedio,
      totalTransacaoXMes,
      top10Associados,
      QUADROS,
      perfilTransacoes,
      saldoInicioGraficoRede,
      saldoRede,
      //totalAssociadosPorSegmento, //Não está em uso
      totalMesCorrente,
      totalMesPassado,
      qtdNegociacoesMes,
      qtdNegociacoesMesPassado,
      ultimasNegociacoes,
    } = await handleFetchDashboard();

    const totalCurrentMonth = +totalMesCorrente.replace(/\./g, '').replace(',', '.');
    const totalCurrentMonthVariation = totalCurrentMonth / +totalMesPassado.replace(/\./g, '').replace(',', '.') || 0;

    const tradingCurrentMonth = +qtdNegociacoesMes;
    const tradingCurrentMonthVariation = tradingCurrentMonth / +qtdNegociacoesMesPassado || 0;

    setCardSells(formatNumberWithoutPrefix(QUADROS.TOTAL_VENDAS_COMPRAS.replace(',', '.')));
    setCardTicketBuy(formatNumberWithoutPrefix(QUADROS.TICKET_MEDIO_COMPRA.replace(',', '.')));
    setCardTicketSell(formatNumberWithoutPrefix(QUADROS.TICKET_MEDIO_VENDA.replace(',', '.')));
    setCardOfferCount(QUADROS.TOTAL_OFERTAS);
    setTotalCurrentMonth(formatNumberWithoutPrefix(totalCurrentMonth));
    setTotalCurrentMonthVariation(formatNumberWithoutPrefix(totalCurrentMonthVariation));
    setTradingCurrentMonth(formatNumberWithoutPrefix(tradingCurrentMonth));
    setTradingCurrentMonthVariation(formatNumberWithoutPrefix(tradingCurrentMonthVariation));

    createTotalVendasXMes(totalVendasXMes, startDate.clone(), endDate.clone());
    createTicketMedio(ticketMedio, startDate.clone(), endDate.clone());
    createTotalTransacaoXMes(totalTransacaoXMes, startDate.clone(), endDate.clone());
    createTop10Associados(top10Associados);
    createPerfilDeTransacoes(perfilTransacoes);
    createSaldoRede(saldoInicioGraficoRede, saldoRede, startDate.clone(), endDate.clone());
    setUltimasNegociacoes(ultimasNegociacoes);

    setIsLoading(false);
  };

  const getColor = (colorNumber: number) => {
    return COLORS[colorNumber % COLORS.length];
  };

  /**
   *
   * @param data The graph data.
   * @param startDate A Moment object. If using filterStartDate or filterEndDate, you must use the Moment.clone() function.
   * @param endDate A Moment object. If using filterStartDate or filterEndDate, you must use the Moment.clone() function.
   */
  const createTotalVendasXMes = (data: graphData[], startDate: Moment, endDate: Moment) => {
    const graphData: typeof totalVendasXMes = [];

    while (startDate.valueOf() <= endDate.valueOf()) {
      const monthData = data.find((monthData) => monthData.DATA_TRANSACAO == startDate.format('YYYY-MM'));

      if (monthData) {
        const month = monthData.DATA_TRANSACAO.split('-')[1];

        graphData.push({
          name: getMonth(+month, 'abbreviated'),
          value: +monthData.VALOR.replace(',', '.'),
        });
      } else {
        const month = startDate.month() + 1;

        graphData.push({
          name: getMonth(month, 'abbreviated'),
          value: 0,
        });
      }

      startDate.add(1, 'months');
    }

    setTotalVendasXMes(graphData);
  };

  /**
   *
   * @param data The graph data.
   * @param startDate A Moment object. If using filterStartDate or filterEndDate, you must use the Moment.clone() function.
   * @param endDate A Moment object. If using filterStartDate or filterEndDate, you must use the Moment.clone() function.
   */
  const createTicketMedio = (data: graphData02, startDate: Moment, endDate: Moment) => {
    const arr: typeof ticketMedio = data.TICKET_VENDA.map((t) => {
      const month = +t.DATA_TRANSACAO.split('-')[1];

      return {
        name: getMonth(month, 'abbreviated'),
        DATA_TRANSACAO: t.DATA_TRANSACAO,
        Venda: +(+t.TICKET.replace(',', '.')).toFixed(2),
        Compra: 0,
      };
    });

    data.SOMA_COMPRA.forEach((soma) => {
      const qtd = +(data.QTD_COMPRA.find((d) => d.DATA_TRANSACAO === soma.DATA_TRANSACAO)?.QTD || 1);
      const ticketCompra = +(+soma.SOMA.replace(',', '.') / qtd + -1).toFixed(2);

      const valueInMonth = arr.find((d) => d.DATA_TRANSACAO === soma.DATA_TRANSACAO);

      if (valueInMonth) {
        valueInMonth.Compra = ticketCompra;
      } else {
        const month = soma.DATA_TRANSACAO.split('-')[1];
        arr.push({
          name: getMonth(month, 'abbreviated'),
          DATA_TRANSACAO: soma.DATA_TRANSACAO,
          Venda: 0,
          Compra: ticketCompra,
        });
      }
    });

    data.QTD_COMPRA.forEach((qtd) => {
      const soma = +(
        data.SOMA_COMPRA.find((d) => d.DATA_TRANSACAO === qtd.DATA_TRANSACAO)?.SOMA.replace(',', '.') || 0
      );
      const ticketCompra = +((soma / +qtd.QTD) * -1).toFixed(2);

      const valueInMonth = arr.find((d) => d.DATA_TRANSACAO === qtd.DATA_TRANSACAO);

      if (valueInMonth) {
        valueInMonth.Compra = ticketCompra;
      } else {
        const month = qtd.DATA_TRANSACAO.split('-')[1];
        arr.push({
          name: getMonth(month, 'abbreviated'),
          DATA_TRANSACAO: qtd.DATA_TRANSACAO,
          Venda: 0,
          Compra: ticketCompra,
        });
      }
    });

    while (startDate.valueOf() <= endDate.valueOf()) {
      const monthData = arr.find((monthData) => monthData.DATA_TRANSACAO == startDate.format('YYYY-MM'));
      if (!monthData) {
        const month = startDate.month() + 1;
        arr.push({
          name: getMonth(month, 'abbreviated'),
          DATA_TRANSACAO: startDate.format('YYYY-MM'),
          Venda: 0,
          Compra: 0,
        });
      }
      startDate.add(1, 'months');
    }

    setTicketMedio(arr.sort((a, b) => compareStrings(a.DATA_TRANSACAO, b.DATA_TRANSACAO)));
  };

  /**
   *
   * @param data The graph data.
   * @param startDate A Moment object. If using filterStartDate or filterEndDate, you must use the Moment.clone() function.
   * @param endDate A Moment object. If using filterStartDate or filterEndDate, you must use the Moment.clone() function.
   */
  const createTotalTransacaoXMes = (data: graphData[], startDate: Moment, endDate: Moment) => {
    const graphData: typeof totalTransacaoXMes = [];

    while (startDate.valueOf() <= endDate.valueOf()) {
      const monthData = data.find((monthData) => monthData.DATA_TRANSACAO == startDate.format('YYYY-MM'));

      if (monthData) {
        const month = monthData.DATA_TRANSACAO.split('-')[1];

        graphData.push({
          name: getMonth(+month, 'abbreviated'),
          value: +monthData.VALOR.replace(',', '.'),
        });
      } else {
        const month = startDate.month() + 1;

        graphData.push({
          name: getMonth(month, 'abbreviated'),
          value: 0,
        });
      }

      startDate.add(1, 'months');
    }

    setTotalTransacaoXMes(graphData);
  };

  /**
   *
   * @param data The graph data.
   */
  const createTop10Associados = (data: graphData04) => {
    setTop10Associados({
      nTransactions: data.nTransactions.map((associateDate) => ({
        name: associateDate.EMP_RAZAOSOCIAL,
        value: +associateDate.QUANTIDADE_TRANSACOES,
      })),
      valueTransactions: data.valueTransactions.map((associateDate) => ({
        name: associateDate.EMP_RAZAOSOCIAL,
        buy: correctNumberWithComma(associateDate.VALOR_COMPRA),
        sell: correctNumberWithComma(associateDate.VALOR_VENDA),
        total: correctNumberWithComma(associateDate.VALOR_TOTAL),
      })),
    });
  };

  const createPerfilDeTransacoes = (data: graphData05) => {
    const graphData: typeof perfilDeTransacoes = [];

    graphData.push({ name: 'Intra', value: correctNumberWithComma(data.mesmaFraquiaVendaECompra.TOTAL) });
    graphData.push({ name: 'Compra', value: correctNumberWithComma(data.fraquiaCompraDiferente.TOTAL) });
    graphData.push({ name: 'Venda', value: correctNumberWithComma(data.fraquiaVendaDiferente.TOTAL) });

    setPerfilDeTransacoes(graphData);
  };

  const createSaldoRede = (
    initialBalance: string,
    months: { [key: string]: string },
    startDate: Moment,
    endDate: Moment
  ) => {
    const graphData: typeof saldoRede = [];
    let balanceFormatted = correctNumberWithComma(initialBalance);

    while (startDate.valueOf() <= endDate.valueOf()) {
      const month = startDate.month() + 1;

      const index = ('0' + month).slice(-2);
      const current = months[index];

      balanceFormatted += correctNumberWithComma(months[('0' + month).slice(-2)]);

      graphData.push({
        name: getMonth(month, 'abbreviated'),
        value: balanceFormatted,
      });

      startDate.add(1, 'months');
    }

    setSaldoRede(graphData);
  };

  const CustomizedXAxisTick = ({ x, y, payload, color, valueChosen, ...rest }: any) => {
    return (
      <Text
        x={x}
        y={y}
        width={40}
        textAnchor="middle"
        verticalAnchor="start"
        style={{ fontSize: '8pt' }}
        stroke={getColor(color({ x, y, payload, ...rest }))}
        strokeWidth=".5"
      >
        {valueChosen({ x, y, payload, ...rest })}
      </Text>
    );
  };

  const CustomizedPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.15;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    const percentFormatted = (percent * 100).toFixed(0);
    const valueFormatted = abbreviateNumber(+value, 0);
    return (
      <Text
        x={x}
        y={y}
        fill="black"
        stroke="none"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="middle"
        width={50}
      >
        {`${percentFormatted}% ${valueFormatted}`}
      </Text>
    );
  };

  return (
    <div className="wrapper">
      <Menu headerAppend={headerAppend} />

      <div className="main-panel">
        <Header title="Dashboard" headerAppend={headerAppend} />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="form-group form-header-filter">
                  <span className="text-color-default">Período: &nbsp;</span>
                  <Datetime
                    dateFormat="MMMM [de] YYYY"
                    timeFormat={false}
                    value={moment(filterStartDate)}
                    onChange={(e) => {
                      const selectedDate = moment(e);
                      if (selectedDate.valueOf() > filterEndDate.valueOf())
                        showAlertWarning({ message: 'A data inicial não pode ser superior a data final' });
                      else changeFilterStartDate(selectedDate);
                    }}
                  />
                  <span className="text-color-default">&nbsp;à&nbsp;</span>
                  <Datetime
                    dateFormat="MMMM [de] YYYY"
                    timeFormat={false}
                    value={moment(filterEndDate)}
                    onChange={(e) => {
                      const selectedDate = moment(e);
                      if (filterStartDate > selectedDate)
                        showAlertWarning({ message: 'A data inicial não pode ser superior a data final' });
                      else changeFilterEndDate(selectedDate);
                    }}
                  />
                  <button type="button" className="btn btn-warning  btn-outline" onClick={(e) => fetchDashboard(true)}>
                    <i className="fas fa-filter" /> Filtrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-link  btn-outline"
                    onClick={() => setIsGrid(!isGrid)}
                    style={{ padding: '4px 5px', color: '#ffa534' }}
                  >
                    <i className={isGrid ? 'fas fa-list' : 'fas fa-th-large'} style={{ fontSize: '20pt' }} />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-success"
                  label="Total VP$"
                  value={cardSells}
                  title="Total no período escolhido"
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-danger"
                  label="Ticket Compra"
                  value={cardTicketBuy}
                  title="Ticket médio de compra no período escolhido"
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-success"
                  label="Ticket Venda"
                  value={cardTicketSell}
                  title="Ticket médio de venda no período escolhido"
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="nc-icon nc-favourite-28"
                  label="Ofertas"
                  value={cardOfferCount}
                  title="Qtd ofertas atualmente"
                  // helper={{
                  //   title: 'O que isto significa?',
                  //   text: 'Mostra a quantidade total de ofertas ativas hoje, independentemente do período escolhido',
                  // }}
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-success"
                  label="Total Mês Corrente"
                  value={totalCurrentMonth}
                  title="Variação"
                  valueSecondary={`${totalCurrentMonthVariation}%`}
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="nc-icon nc-money-coins text-success"
                  label="Negociações Mês Corrente"
                  value={tradingCurrentMonth}
                  title="Variação"
                  valueSecondary={`${tradingCurrentMonthVariation}%`}
                />
              </div>
            </div>
            <div className="row">
              <div className={isGrid ? 'col-12' : 'col-sm-12 col-md-6'}>
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Total Vendas x Mês</h4>
                    <p className="card-category">Somatório do valor de todas as vendas nos ultimos 6 meses</p>
                  </div>
                  <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ResponsiveContainer height={230} width={'100%'} className="graph-label">
                      <BarChart data={totalVendasXMes} syncId="syncChartId">
                        <XAxis
                          dataKey="name"
                          tick={
                            <CustomizedXAxisTick
                              valueChosen={({ payload: { value } }: any) => value}
                              color={({ payload: { value } }: any) => getMonth(value, 'number')}
                            />
                          }
                        />
                        <YAxis dataKey="value" />
                        <Tooltip
                          labelFormatter={(label) => getMonth(label, 'extensive')}
                          formatter={(e) => [formatNumberToVPS(+e), 'Valor']}
                          cursor={false}
                        />
                        <Bar dataKey="value" fill="#8884d8">
                          {totalVendasXMes.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(getMonth(entry.name, 'number'))} />
                          ))}
                          <LabelList
                            dataKey="value"
                            position="top"
                            offset={5}
                            angle={0}
                            formatter={(e) => abbreviateNumber(+e, 0)}
                          />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className={isGrid ? 'col-12' : 'col-sm-12 col-md-6'}>
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Ticket Médio</h4>
                    <p className="card-category">Ticket médio referente a todas transações nos ultimos 6 meses</p>
                  </div>
                  <div className="card-body" style={{ marginLeft: -10, paddingLeft: 0, paddingRight: 0 }}>
                    <ResponsiveContainer height={230} width={'100%'} className="graph-label">
                      <BarChart data={ticketMedio} syncId="syncChartId">
                        <XAxis
                          dataKey="name"
                          tick={
                            <CustomizedXAxisTick
                              valueChosen={({ payload: { value } }: any) => value}
                              color={({ payload: { value } }: any) => getMonth(value, 'number')}
                            />
                          }
                        />
                        <YAxis />
                        <Tooltip
                          labelFormatter={(label) => getMonth(label, 'extensive')}
                          formatter={(e) => formatNumberToVPS(+e)}
                          cursor={false}
                        />
                        <Bar dataKey="Compra" fill="#da7b34">
                          <LabelList
                            dataKey="Compra"
                            position="top"
                            offset={5}
                            angle={0}
                            formatter={(e) => abbreviateNumber(+e, 0)}
                          />
                        </Bar>
                        <Bar dataKey="Venda" fill="#6495ed">
                          <LabelList
                            dataKey="Venda"
                            position="top"
                            offset={5}
                            angle={0}
                            formatter={(e) => abbreviateNumber(+e, 0)}
                          />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={isGrid ? 'col-12' : 'col-sm-12 col-md-6'}>
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Total Transação x mês</h4>
                    <p className="card-category">Somatório do valor de todas as transações nos ultimos 6 meses</p>
                  </div>
                  <div className="card-body" style={{ height: 415, width: '100%' }}>
                    <ResponsiveContainer height={'95%'} width={'100%'} className="graph-label">
                      <BarChart data={totalTransacaoXMes} stackOffset="sign" syncId="syncChartId">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="name"
                          tick={
                            <CustomizedXAxisTick
                              valueChosen={({ payload: { value } }: any) => value}
                              color={({ payload: { value } }: any) => getMonth(value, 'number')}
                            />
                          }
                        />
                        <YAxis dataKey="value" />
                        <Tooltip
                          labelFormatter={(label) => getMonth(label, 'extensive')}
                          formatter={(e) => [formatNumberToVPS(+e), 'Valor']}
                          cursor={false}
                        />
                        <ReferenceLine y={0} stroke="#000" />
                        <Bar dataKey="value" fill="#8884d8">
                          {totalTransacaoXMes.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(getMonth(entry.name, 'number'))} />
                          ))}
                          <LabelList
                            dataKey="value"
                            position="top"
                            offset={5}
                            angle={0}
                            formatter={(e) => abbreviateNumber(+e, 0)}
                          />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className={isGrid ? 'col-12' : 'col-sm-12 col-md-6'}>
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Saldo Rede</h4>
                    <p className="card-category">Saldo da rede nos ultimos 6 meses</p>
                  </div>
                  <div className="card-body" style={{ height: 415, width: '100%' }}>
                    <ResponsiveContainer height={'95%'} width={'100%'} className="graph-label">
                      <BarChart data={saldoRede} syncId="syncChartId">
                        <XAxis
                          dataKey="name"
                          tick={
                            <CustomizedXAxisTick
                              valueChosen={({ payload: { value } }: any) => value}
                              color={({ payload: { value } }: any) => getMonth(value, 'number')}
                            />
                          }
                        />
                        <YAxis dataKey="value" />
                        <Tooltip
                          labelFormatter={(label) => getMonth(label, 'extensive')}
                          formatter={(e) => [formatNumberToVPS(+e), 'Valor']}
                          cursor={false}
                        />
                        <Bar dataKey="value" fill="#8884d8">
                          {saldoRede.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(getMonth(entry.name, 'number'))} />
                          ))}
                          <LabelList
                            dataKey="value"
                            position="top"
                            offset={5}
                            angle={0}
                            formatter={(e) => abbreviateNumber(+e, 0)}
                          />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={isGrid ? 'col-12' : 'col-sm-12 col-md-6'}>
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Perfil de Transações</h4>
                    <p className="card-category">Perfil das transações nos ultimos 6 meses</p>
                  </div>
                  <div className="card-body" style={{ height: 415, width: '100%' }}>
                    <ResponsiveContainer height={'100%'} width={'100%'}>
                      <PieChart width={400} height={400}>
                        <Pie
                          isAnimationActive={false}
                          data={perfilDeTransacoes}
                          dataKey="value"
                          cx={'50%'}
                          cy={'50%'}
                          labelLine
                          label={<CustomizedPieLabel />}
                          outerRadius={150}
                          fill="#8884d8"
                        >
                          {perfilDeTransacoes.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip formatter={(text) => formatNumberToVPS(+text)} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className={isGrid ? 'col-12' : 'col-sm-12 col-md-6'}>
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Top 10 Associados</h4>
                    <p className="card-category">
                      {top10AssociadosType === 'nTransactions'
                        ? 'Os 10 associados com o maior número de transações nos ultimos 6 meses'
                        : top10AssociadosType === 'valueTransactions'
                        ? 'Os 10 associados com o maior valor transacionado nos ultimos 6 meses'
                        : ''}
                    </p>
                    <p className="card-category">
                      <div className="form-check checkbox-inline form-check-radio">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="top10Type"
                            checked={top10AssociadosType === 'nTransactions'}
                            onChange={() => setTop10AssociadosType('nTransactions')}
                          />
                          <span className="form-check-sign"></span>
                          N° de transações
                        </label>
                      </div>
                      <div className="form-check checkbox-inline form-check-radio">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="top10Type"
                            checked={top10AssociadosType === 'valueTransactions'}
                            onChange={() => setTop10AssociadosType('valueTransactions')}
                          />
                          <span className="form-check-sign"></span>
                          Valor transacionado
                        </label>
                      </div>
                    </p>
                  </div>
                  <div className="card-body" style={{ height: 415, width: '100%' }}>
                    <ResponsiveContainer height={'95%'} width={'100%'} className="graph-label">
                      <ComposedChart
                        layout="vertical"
                        data={
                          top10AssociadosType === 'nTransactions'
                            ? top10Associados.nTransactions
                            : top10AssociadosType === 'valueTransactions'
                            ? top10Associados.valueTransactions
                            : []
                        }
                        margin={{
                          top: 0,
                          right: 0,
                          left: 30,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis type="number" />
                        <YAxis
                          dataKey="name"
                          type="category"
                          tickFormatter={(a: string) => {
                            const nameSplitted = a.split(' ');
                            return nameSplitted.length === 1
                              ? nameSplitted[0]
                              : nameSplitted[0] + ' ' + nameSplitted[1];
                          }}
                        />
                        <Legend
                          formatter={(field) => {
                            if (field === 'buy') return 'Compra';
                            else if (field === 'sell') return 'Venda';
                            else if (field === 'value') return 'Quantidade de transações';
                            else return '';
                          }}
                        />
                        {top10AssociadosType === 'nTransactions'
                          ? [
                              <Bar dataKey="value" barSize={20} fill="#413ea0">
                                <LabelList dataKey="value" position="right" />
                              </Bar>,
                              <Tooltip formatter={(value, name) => [value, 'Qtd transações']} />,
                            ]
                          : [
                              <Bar
                                key="composedChartStackKey01"
                                dataKey="buy"
                                stackId="composedChartStackId"
                                fill="#413ea0"
                              >
                                {/* <LabelList dataKey="buy" formatter={(e) => (+e === 0 ? '' : abbreviateNumber(+e))} /> */}
                              </Bar>,
                              <Bar
                                key="composedChartStackKey02"
                                dataKey="sell"
                                stackId="composedChartStackId"
                                fill="#82ca9d"
                              >
                                {/* <LabelList dataKey="sell" formatter={(e) => (+e === 0 ? '' : abbreviateNumber(+e))} /> */}
                                <LabelList dataKey="total" position="right" formatter={(e) => abbreviateNumber(+e)} />
                              </Bar>,
                              <Tooltip
                                formatter={(value, name) => [
                                  formatNumberToVPS(+value),
                                  name === 'buy' ? 'Compra' : name === 'sell' ? 'Venda' : '',
                                ]}
                              />,
                            ]}
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className={'col-12'}>
                <div className="card">
                  <div className="card-header ">
                    <h4 className="card-title">Últimas Negociações</h4>
                    <p className="card-category">As 10 ultimas transações dos associados</p>
                  </div>
                  <div className="card-body">
                    <TableWithLinks
                      emptyTableMessage="Não há transações"
                      columns={[
                        { field: 'TRA_DATA_FORMAT', label: 'Data' },
                        { field: 'buyer', label: 'Comprador' }, //FRANQUIA
                        { field: 'valueFormatted', label: 'Valor' },
                        { field: 'salesman', label: 'Vendedor' }, //FRANQUIA2
                      ]}
                      data={ultimasNegociacoes.map((negociacao) => ({
                        ...negociacao,
                        valueFormatted: (
                          <span style={{ color: correctNumberWithComma(negociacao.TRA_VALOR) > 0 ? 'blue' : 'red' }}>
                            {formatNumberToVPS(correctNumberWithComma(negociacao.TRA_VALOR))}
                          </span>
                        ),
                        buyer: [
                          <>
                            <i className="fas fa-shopping-bag" style={{ color: 'blue' }} /> {negociacao.COMPRA}
                          </>,
                          negociacao.FRANQUIA,
                        ],
                        salesman: [
                          <>
                            <i className="fas fa-store-alt" style={{ color: 'blueviolet' }} /> {negociacao.VENDA}
                          </>,
                          negociacao.FRANQUIA2,
                        ],
                      }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewDashboard;
