import React from 'react';
import IndicatorCard from '~/components/card/IndicatorCard';
import LayoutMain from '~/layout/main';
import { ViewAssociadosProps } from '~/types';
import CardHeader from '~/components/card/CardHeader';
import { formatCNPJ, formatCPF, formatPhone, openModal } from '~/utils/util';
import TableWithLinks from '~/components/table/TableWithLinks';

const ViewAssociados: React.FunctionComponent<ViewAssociadosProps> = ({
  isLoading,
  headerConfig,
  cardHeader,
  cards,
  ModalAssociate,
  ModalFilter,
  associates,
  openModalAssociateAdd,
  openModalAssociateEdit,
  exportAssociates,
}) => {
  return (
    <LayoutMain isLoading={isLoading} {...headerConfig}>
      {cards !== undefined && (
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <IndicatorCard
              icon="nc-icon nc-icon nc-bank text-danger"
              label="Inativos"
              value={cards.cardInactiveAssociates}
              title="Associados inativos"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <IndicatorCard icon="nc-icon nc-icon nc-bank text-success" label="Ativos" value={cards.cardActiveAssociates} title="Associados ativos" />
          </div>
          <div className="col-lg-4 col-sm-12">
            <IndicatorCard icon="nc-icon nc-icon nc-bank text-danger" label="VPMIX" value={cards.cardAssociatesVPMIX} title="Associados no VPMIX" />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="card table-with-links">
            <CardHeader
              {...cardHeader}
              actions={cardHeader.actionAddAssociate ? [cardHeader.actionAddAssociate] : undefined}
              extraActions={
                exportAssociates ? [{ iconClass: 'far fa-file-excel', title: 'Exportar para Excel', action: exportAssociates }] : undefined
              }
            />

            {ModalAssociate}
            {ModalFilter}

            <div className="card-body table-full-width">
              <TableWithLinks
                isLoading={isLoading}
                emptyTableMessage="Nenhum associado encontrado. Tente alterar os filtros."
                onClickingRow={openModalAssociateEdit}
                columns={[
                  { field: 'CPFCNPJFormatted', label: 'CPF ou CNPJ', alignment: 'text-center', width: '150px' },
                  { field: 'EMP_RAZAOSOCIAL', label: 'Razão Social' },
                  { field: 'EMP_NOMEFANTASIA', label: 'N.fantasia' },
                  { field: 'ITI_DESCRICAO', label: 'Situação', alignment: 'text-center', width: '95px' },
                  { field: 'EMP_CONTATOADMIN', label: 'Contato', alignment: 'text-center' },
                  { field: 'PhoneFormatted', label: 'Telefone', alignment: 'text-center', width: '120px' },
                  { field: 'EMP_EMAILADMIN', label: 'Email', alignment: 'text-right' },
                ]}
                data={associates.map((associate) => ({
                  ...associate,
                  CPFCNPJFormatted: associate.EMP_CPFCNPJ.length === 11 ? formatCPF(associate.EMP_CPFCNPJ) : formatCNPJ(associate.EMP_CPFCNPJ),
                  PhoneFormatted: formatPhone(associate.EMP_TELEFONEADMIN),
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ViewAssociados;
