import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { EmpType } from '~/enum';
import { baseURL } from '~/config/api';
import { MenuProps, MenuRouteSettings, RouteSettings } from '~/types';
import { isRoute, menus } from '~/routes';
import logoInLine from '~/components/header/logo.png';
import Select, { components } from 'react-select';

const Menu: React.FunctionComponent<MenuProps> = ({ resetErrorBoundary, headerAppend }) => {
  const { user, company: empresa, setCompany } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    $('html').removeClass('nav-open');

    //   const $navbar = $('nav').find('.navbar-collapse').first().clone(true);

    //   let nav_content = '';

    //   //add the content from the regular header to the mobile menu
    //   $navbar.children('ul').each(function () {
    //     nav_content = nav_content + $(this).html();
    //   });

    //   // insert the navbar form before the sidebar list
    //   const $nav_content = $('<ul className="nav nav-mobile-menu">' + nav_content + '</ul>');

    //   $nav_content.insertBefore($('.sidebar-wrapper').find(' > .nav'));
  }, [location.pathname]);

  const createMenus = (empType: EmpType, menus: (RouteSettings | MenuRouteSettings)[]) => {
    return menus
      .filter((menu) => menu.type === empType)
      .map((menu) =>
        isRoute(menu) ? (
          menu.show && (
            <li
              key={'menu-key' + menu.path}
              className={`nav-item ${window.location.pathname == menu.path ? 'active' : ''}`}
              onClick={resetErrorBoundary}
            >
              <Link className="nav-link" to={menu.path}>
                <i className={menu.iconClass}></i>
                <p>{menu.label}</p>
              </Link>
            </li>
          )
        ) : (
          <li key={'menu-key' + menu.iconClass} className="nav-item">
            <a className="nav-link collapsed" data-toggle="collapse" href={'#' + menu.label} aria-expanded="false">
              <i className={menu.iconClass}></i>
              <p>
                {menu.label}
                <b className="caret"></b>
              </p>
            </a>
            <div
              className={`collapse ${
                menu.routes.map((route) => route.path).includes(window.location.pathname) ? 'show' : ''
              }`}
              id={menu.label}
            >
              <ul className="nav">
                {menu.routes.map((route) => (
                  <li
                    key={'menu-key' + route.path}
                    className={`nav-item ${window.location.pathname == route.path ? 'active' : ''}`}
                    onClick={resetErrorBoundary}
                  >
                    <Link className="nav-link" to={route.path}>
                      <span className="sidebar-normal">{route.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        )
      );
  };

  const changeCompany = (companyId: number) => {
    const company = user.EMPRESAS?.find(({ EMP_CODIGO }) => EMP_CODIGO == companyId);
    if (company) {
      setCompany({ ...company, EMP_TIPO: empresa.EMP_TIPO });
      window.location.reload();
    }
  };

  return (
    <div
      className="sidebar"
      data-color="orange"
      data-image="../../assets/img/sidebar-5.jpg"
      style={{ overflowY: 'hidden' }}
    >
      <div className="sidebar-wrapper">
        <div className="logo">
          <a
            href="http://www.viapermuta.com.br"
            target="_blank"
            className="simple-text logo-mini sidebar-mini-show"
            style={{ top: '25px', position: 'absolute' }}
          >
            VP
          </a>
          <a href="http://www.viapermuta.com.br" target="_blank" className="logo-normal">
            <img
              className="logo-normal mx-auto d-block"
              style={{ height: 70, backgroundColor: 'white', padding: '10px 73px' }}
              src={logoInLine}
            />
          </a>
          {/* <a href="http://www.viapermuta.com.br" target="_blank" className="simple-text logo-mini">
              VP
            </a>
            <a href="http://www.viapermuta.com.br" target="_blank" className="simple-text logo-normal">
              VIA PERMUTA
            </a> */}
        </div>
        <div className="user">
          <div className="photo">
            <img
              src={baseURL + '/web/uploads/fotoPerfil/usuario/' + user.USU_CODIGO + '.png'}
              onError={(e) => (e.currentTarget.src = baseURL + '/web/img/layout/Perfil.PNG')}
            />
          </div>
          <div className="info ">
            <a data-toggle="collapse" href="#collapseExample" className="collapsed">
              <span>
                <span className="username" title={user.USU_TRATAMENTO !== '' ? user.USU_TRATAMENTO : user.USU_NOME}>
                  {user.USU_TRATAMENTO !== '' ? user.USU_TRATAMENTO : user.USU_NOME}
                </span>
                <b className="caret"></b>
              </span>
            </a>
            <div className="collapse" id="collapseExample">
              <ul className="nav">
                <li>
                  <Link
                    className="profile-dropdown"
                    to={
                      empresa.EMP_TIPO === EmpType.FRANQUIA
                        ? '/franquia/meu-perfil'
                        : empresa.EMP_TIPO === EmpType.ASSOCIADO
                        ? '/associado/meu-perfil'
                        : empresa.EMP_TIPO === EmpType.FRANQUEADORA
                        ? '/franqueadora/meu-perfil'
                        : ''
                    }
                  >
                    <span className="sidebar-mini">MP</span>
                    <span className="sidebar-normal">Meu Perfil</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {empresa?.EMP_TIPO === EmpType.MASTER_FRANQUIA && (
          <ul className="nav nav-mobile-menu">
            <div
              className="nav-mobile-hide"
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: '#888888',
                minWidth: '250px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Select
                styles={{
                  container: (props) => ({ ...props, width: '250px' }),
                  control: (style, props) => ({
                    ...style,
                    border: 'var(--color-default) 1px solid',
                    borderColor: 'var(--color-default) !important',
                    boxShadow: props.isFocused ? '0 0 0 1px orange' : undefined,
                  }),
                }}
                defaultValue={{
                  value: empresa.EMP_CODIGO,
                  label: empresa.EMP_NOMEFANTASIA,
                  labelAux: empresa.EMP_RAZAOSOCIAL,
                }}
                options={
                  user.EMPRESAS?.map((company) => ({
                    value: company.EMP_CODIGO,
                    label: company.EMP_NOMEFANTASIA,
                    labelAux: company.EMP_RAZAOSOCIAL,
                  })) || []
                }
                onChange={(selected) => selected && changeCompany(selected.value)}
                getOptionLabel={(option) => option.label}
                components={{
                  Option: (props) => (
                    <components.Option {...props}>
                      <div className="d-flex flex-column text-dark">
                        <span>{props.label}</span>
                        <small>{props.data.labelAux}</small>
                      </div>
                    </components.Option>
                  ),
                }}
              />
            </div>
          </ul>
        )}

        {empresa.EMP_TIPO === EmpType.ASSOCIADO && (
          <ul className="nav nav-mobile-menu">
            <div
              className="d-flex nav-mobile-hide"
              style={{
                flexDirection: 'column',
                color: '#fff',
                minWidth: '180px',
                justifyContent: 'center',
                alignItems: 'flex-start',
                marginLeft: '25px',
              }}
            >
              <small style={{ fontWeight: '600' }}>Vencimento: {empresa.EMP_DATACADASTRO.slice(0, 5)}</small>
              <small style={{ fontWeight: '600' }}>Segmento: {empresa.SEG_DESCRICAO}</small>
            </div>
          </ul>
        )}

        {headerAppend && (
          <ul className="nav nav-mobile-menu">
            <div className="nav-mobile-hide">
              <span className="nav-item">
                <span className="nav-link">{headerAppend}</span>
              </span>
            </div>
          </ul>
        )}

        <ul className="nav nav-mobile-menu">
          <a href="/" className="nav-item">
            <span className="nav-link">
              <span className="d-lg-none">Sair</span>
              <i className="nc-icon nc-button-power"></i>
            </span>
          </a>
        </ul>

        <ul className="nav">{createMenus(empresa.EMP_TIPO as EmpType, menus)}</ul>
        {/* {
          // JSON.parse(String(localStorage.getItem('tipo'))) === UserType.ASSOCIADO &&
          empresa.EMP_TIPO === EmpType.ASSOCIADO && <MenusAssociado />
        }

        {
          // JSON.parse(String(localStorage.getItem('tipo'))) === UserType.FRANQUIA &&
          empresa.EMP_TIPO === EmpType.FRANQUIA && <MenusFranquia />
        } */}
      </div>
    </div>
  );
};

export default Menu;
