import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import BasicInput from '~/components/input/BasicInput';
import BasicTextarea from '~/components/input/BasicTextarea';
import SelectControlled from '~/components/input/SelectControlled';

interface ModalVisitaProps {
  onSubmit: (data: any, reset: any) => void;
  associados: never[];
  gerentes: never[];
  retornos: never[];
  isAwaitResponse: boolean;
  schema: any;
}

const ModalVisita: React.FC<ModalVisitaProps> = ({ schema, associados, gerentes, retornos, onSubmit, isAwaitResponse }) => {
  const { control, handleSubmit, errors, register, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      emp_codigo: '',
      usu_codigo: '',
      uva_data: '',
      uva_description: '',
      rva_codigo: '',
    },
  });

  return (
    <div className="modal fade" id="modalVisias" role="dialog" tabIndex={-1}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <form onSubmit={handleSubmit((data) => onSubmit(data, reset))} id="formIdVisista">
              <div className="row">
                <div className="col-md-12">
                  <div className="card stacked-form">
                    <div className="card-header ">
                      <h4 className="card-title">Cadastro de Visita a Associados</h4>
                    </div>
                    <div className="card-body ">
                      <SelectControlled
                        control={control}
                        isRequired
                        defaultValue={''}
                        options={associados.map((associado: any) => ({
                          value: associado.EMP_CODIGO,
                          label: associado.EMP_RAZAOSOCIAL,
                        }))}
                        hasError={!!errors.emp_codigo}
                        name="emp_codigo"
                        label="Associados"
                      />
                      <SelectControlled
                        control={control}
                        isRequired
                        defaultValue={''}
                        options={gerentes.map((gerente: any) => ({
                          value: gerente.USU_CODIGO,
                          label: gerente.USU_NOME,
                        }))}
                        hasError={!!errors.usu_codigo}
                        name="usu_codigo"
                        label="Gerentes de Contas"
                      />
                      <SelectControlled
                        control={control}
                        isRequired
                        defaultValue={''}
                        options={retornos.map((retorno: any) => ({
                          value: retorno.RVA_CODIGO,
                          label: retorno.RVA_TITULO,
                        }))}
                        hasError={!!errors.rva_codigo}
                        name="rva_codigo"
                        label="Retonor de Visita"
                      />

                      <BasicInput
                        isRequired
                        control={control}
                        type="datetime-local"
                        defaultValue={''}
                        hasError={!!errors.uva_data}
                        name="uva_data"
                        label="Data e hora da visita"
                      />

                      <BasicTextarea
                        ref={register}
                        defaultValue=""
                        hasError={!!errors.uva_description}
                        height="100px"
                        name="uva_description"
                        label="Observação"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            <button className="btn btn-success btn-wd" form="formIdVisista" type="submit" disabled={isAwaitResponse}>
              {isAwaitResponse ? (
                <>
                  <i className="fas fa-spinner rotating" /> Processando...
                </>
              ) : (
                <>
                  <i className="fa fa-save" /> Salvar
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVisita;
