import React from 'react';
import InputMaskControlled from '~/components/input/InputMaskControlled';
import { MaskMoneyControlledProps } from '~/types';
import { formatNumberToMoney, formatNumberToVPS } from '~/utils/util';

const MaskMoneyControlled: React.FunctionComponent<MaskMoneyControlledProps> = ({ prefix, disabled, ...rest }) => {
  return (
    <InputMaskControlled
      {...rest}
      placeholder={prefix}
      isNumericString
      prefix={prefix}
      isMoneyMask
      format={(e) => (prefix === 'VP$' ? formatNumberToVPS(+e / 100) : formatNumberToMoney(+e / 100))}
      isDisabled={disabled}
    />
  );
};

export default MaskMoneyControlled;
